import * as React from "react";
import { CheckIcon, DesktopComputerIcon } from "@heroicons/react/solid";
import Layout from "../components/layout";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";
import ListComponent from "../components/ui/ListComponent";
import {StaticImage} from "gatsby-plugin-image";
import CourseFormComponent from "../components/ui/CourseFormComponent";

const listZero = [
    {
        link: false,
        icon: CheckIcon,
        desc: "Jahongir Rahimov, 34 yosh",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "Ish tajribasi 7 yil",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "2019-2021 yillarda Stekloplastik, UZMBC va boshqa yirik kompaniyalar uchun web-saytlar ishlab chiqargan. Loyihalarning umumiy soni – 15 ta.",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "2022-yil yanvar oyidan 2023-yil iyul oyigacha Kipr davlatida joylashgan Albato kompaniyasida Frontend yoʻnalishi boʻyicha LK Albato loyihasida ishlagan. Embedded LK WL loyihasini va Albato saytida Embedded sahifasini ishlab chiqargan.",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "Hozirda Chaqqon - ovqat yetkazib berish va taksi xizmati loyihalari ustida ishlamoqda",
    },
    {
        icon: CheckIcon,
        desc: "Batafsil LinkedIn profilida",
        link: true,
    },
];

const listOne = [
    {
        link: false,
        icon: CheckIcon,
        desc: "Siz korporativ web-saytlar, internet magazinlar, har qanday murakkablikdagi web-saytlarni ishlab chiqishingiz mumkin",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "Siz Frontend dasturchisi, Javascript dasturchisi, React dasturchisi kabi vakansiyalarga ariza topshirishingiz va masofadan turib onlayn ishlashingiz mumkin.",
    },
    {
        link: false,
        icon: CheckIcon,
        desc: "Amaliy ko'nikmalar va yaxshi portfelga ega bo'lasiz",
    },
];

const listTwo = [
    {
        icon: DesktopComputerIcon,
        desc: "Ishonchli kompyuter foydalanuvchisi",
        link: false,
    },
    {
        icon: DesktopComputerIcon,
        desc: "O'zingiz bilan noutbuk olib keling",
        link: false,
    },
];
const listThree = [
    {
        icon: CheckIcon,
        desc: "Keng ish tajribasi",
        link: false,
    },
    {
        icon: CheckIcon,
        desc: "Biz bu sohada haqiqiy mutaxassislarmiz",
        link: false,
    },
    {
        icon: CheckIcon,
        desc: "Haqiqiy loyihalar",
        link: true,
    },
];

export default function KurslarPage({location}) {

    return (
        <Layout location={location.pathname} title="Frontend dasturlash kursiga marhamat" description="Kursni tugatgandan so'ng siz korporativ web-saytlar, internet magazinlar, har qanday murakkablikdagi web-saytlarni ishlab chiqishingiz mumkin">
            <MarginComponent small="sm:mt-8" margin="mt-10">
                <div className="rounded-lg bg-white overflow-hidden shadow">
                    <div className="flex flex-col items-center p-4 pt-10 sm:p-8">
                        <StaticImage src="../../images/js.png" alt="Курсы программирования" />
                        <MarginComponent small="sm:mt-8" margin="mt-10">
                            <TitleComponent>Frontend dasturlash kursi</TitleComponent>
                            <MarginComponent small="sm:mt-8" margin="mt-10"></MarginComponent>
                            <TitleComponent as="h3">Kurs muallifi</TitleComponent>
                            <ListComponent data={listZero} />
                            <MarginComponent small="sm:mt-8" margin="mt-10"></MarginComponent>
                            <TitleComponent as="h3">Kursni tugatgandan so'ng</TitleComponent>
                            <ListComponent data={listOne} />
                            <MarginComponent small="sm:mt-8" margin="mt-10">
                                <TitleComponent as="h3">Talablar</TitleComponent>
                                <ListComponent data={listTwo} bg="bg-yellow-500" />
                            </MarginComponent>
                            <MarginComponent small="sm:mt-8" margin="mt-10">
                                <TitleComponent as="h3">Bizning afzalliklarimiz</TitleComponent>
                                <ListComponent data={listThree} />
                            </MarginComponent>
                        </MarginComponent>
                    </div>

                    <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                        <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Kursning boshlanish sanasi
              </span>{" "}
                            <br />
                            <span className="text-gray-600">1 oktyabr</span>
                            <br />
                            <span className="text-red-400">Qabul qilish ochildi</span>
                        </div>
                        <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Davomiyligi
              </span>{" "}
                            <br />
                            <span className="text-gray-600">6 oy</span>
                            <br />
                            <span className="text-gray-600">Haftada 2 ta dars</span>
                            <br />
                            <span className="text-gray-600">1 dars - 120 daqiqa</span>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                        <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Narxi
              </span>{" "}
                            <br />
                            <span className="text-gray-600">500 000 so'm</span>
                        </div>
                        <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Guruhda
              </span>{" "}
                            <br />
                            <span className="text-gray-600">5 kishi</span>
                        </div>
                    </div>
                </div>
                <MarginComponent small="sm:mt-8" margin="mt-10">
                    <TitleComponent>Kursga yoziling</TitleComponent>
                    <CourseFormComponent />
                </MarginComponent>
                {/*<SocialButtons />*/}
            </MarginComponent>
        </Layout>
    );
}
