import React, { useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function CourseFormComponent() {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        formState: { errors, isSubmitted },
    } = useForm();
    const onSubmit = (data) => {
        const msg = encodeURIComponent(
            "<b>----------F.I.Sh----------\n\n</b>" +
            `${data.fullname}\n\n` +
            "<b>----------Номер телефона----------\n\n</b>" +
            `${data.phone}\n\n`
        );
        const url = `https://api.telegram.org/bot6486223579:AAEDHnPHrfY4JrdUpK4C7EBR8MA7cLVbaS4/sendMessage?chat_id=5777355636&parse_mode=HTML&text=${msg}`;
        axios
            .get(url)
            .then(function (response) {
                // actions.setStatus({ msg: "Ваша заявка принята" });
                // setTimeout(actions.resetForm, 3000);
                console.log(response);
                setIsSuccessfullySubmitted(response.data.ok);
                reset();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div className="relative bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
            <div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="grid grid-cols-1 gap-y-2"
                >
                    <div className="sm:flex">
                        <div className="w-full mb-2 sm:mb-0">
                            <label htmlFor="fullname" className="sr-only">
                                Familiya Ism Sharif
                            </label>
                            <input
                                {...register("fullname", { required: true })}
                                type="text"
                                name="fullname"
                                id="fullname"
                                autoComplete="name"
                                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 border border-gray-300 rounded-md text-sm"
                                placeholder="Familiya Ism Sharif"
                                disabled={isSuccessfullySubmitted}
                            />
                            {errors.fullname && (
                                <p className="text-yellow-600 text-xs">Majburiy maydon</p>
                            )}
                        </div>
                    </div>
                    <div className="sm:flex">
                        <div className="w-full mb-2 sm:mb-0">
                            <label htmlFor="phone" className="sr-only">
                                Telefon raqam
                            </label>

                            <Controller
                                {...register("phone", { required: true })}
                                name="phone"
                                control={control}
                                rules={{
                                    validate: (value) => isValidPhoneNumber(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        disabled={isSuccessfullySubmitted}
                                        placeholder="Telefon raqam"
                                        value={value}
                                        onChange={onChange}
                                        defaultCountry="UZ"
                                        id="phone"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 border border-gray-300 rounded-md text-sm"
                                    />
                                )}
                            />
                            {errors["phone"] && (
                                <p className="text-yellow-600 text-xs">Majburiy maydon</p>
                            )}
                        </div>
                    </div>

                    {isSuccessfullySubmitted && (
                        <div className="text-green-500 text-xs">Yuborildi. Tez orada siz bilan bog'lanamiz!</div>
                    )}
                    <div>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md"
                        >
                            Yuborish
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
